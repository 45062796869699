let Faq = {
    init: () => {
        initFaqSearch();
        handleSearchFilters();
    }
};

const MAX_SEARCH_RESULTS_TO_SHOW = 5,
      Product = {
        ALL: 'all',
        GO: 'go',
        LEAP: 'leap',
        XOLO_SPAIN: 'xolo-spain-en'
      },
      availableProducts = [
          Product.ALL,
          Product.GO,
          Product.LEAP,
          Product.XOLO_SPAIN
      ];

const initFaqSearch = () => {
    const $search = $('#search'), $searchFail = $('#searchFail');

    if (!$search.length) {
        return;
    }

    const resultsMap = new Map();

    $search.autocomplete({
        minLength: 1,
        appendTo: '.b-faq__search > .l-container',
        source(request, response) {
            $.get(getSearchArticleUrl($search.val(), getSelectedProduct()), data => {
                if (!data.length) {
                    response([{
                        label: 'No matches found.',
                        value: 'no_matches'
                    }]);
                    return;
                }

                resultsMap.clear();

                data.forEach(searchArticle => {
                    resultsMap.set(searchArticle.urlSlug, searchArticle.publicArticle);
                });

                response($.map(data, value => {
                    return {
                        label: value.titleHighlight,
                        value: value.urlSlug,
                        product: value.productFolderName
                    };
                }));
            })
                .fail(() => $searchFail.show())
                .done(() => $searchFail.hide());
        },
        select(event, ui) {
            event.preventDefault();

            if (ui.item.value !== 'no_matches') {
                window.location = Config.options.rootUrl + Config.options.regionAndLanguage + 'faq/article/' + ui.item.value + '/product/' + ui.item.product;
            }
        },
        open: function(event, ui) {
            $(this).addClass('open');

            $('.ui-autocomplete').append('' +
                '<ul class="list-filters">' +
                '<li class="link-filters">' +
                'Results: ' +
                '<span class="filter" data-product="all">All</span>' + ' / ' +
                '<span class="filter" data-product="go">Go</span>' + ' / ' +
                '<span class="filter" data-product="leap">Leap</span>' + ' / ' +
                '<span class="filter" data-product="xolo-spain-en">Xolo Spain</span>' +
                '</li>' +
                '</ul>'
            ).find(`span[data-product="${getSelectedProduct()}"]`).click();
        },
        close: function(event, ui) {
            $(this).removeClass('open');
        }
    }).focus(function() {
        if ($(this).autocomplete('widget').is(':visible')) return;

        $(this).autocomplete('search');
    }).data('ui-autocomplete')._renderItem = function(ul, item) {
        if (item.value === 'no_matches') return $('<li class="ui-state-disabled link-more" style="opacity:1;text-align:left;">' + item.label + '</li>').appendTo(ul);

        let locked = !resultsMap.get(item.value) && !$search.data('authorized') && item.value !== 'no_matches',
            listItemContent = '<div>' + item.label + '<div class=\'product-type\'>' + item.product + '</div>' + '</div>',
            listItem = $('<li></li>').data('item.autocomplete', item).append(listItemContent).appendTo(ul);

        if (locked) listItem.addClass('locked');
        if (item.product === 'Xolo Go') listItem.addClass('product-go');
        if (item.product === 'Xolo Leap') listItem.addClass('product-leap');
        if (item.product === 'Xolo Spain' || item.product === 'Xolo') listItem.addClass('product-xolo-spain-en');

        return listItem;
    };

    jQuery.ui.autocomplete.prototype._resizeMenu = function() {
        let ul = this.menu.element;
        ul.outerWidth(this.element.outerWidth());
    };

    $search.keypress(event => {
        if (event.keyCode == 13) {
            window.location = getSearchUrl($search.val(), getSelectedProduct());
        }
    });
}

const checkForTooManyResults = () => {
    const $visibleSearchResultItems = $('.ui-menu-item:visible'),
        $listFilter = $('.list-filters'),
        $linkMore = $('.link-more'),
        moreResultsThanAllowed = $visibleSearchResultItems.length > MAX_SEARCH_RESULTS_TO_SHOW;

    $visibleSearchResultItems.hide();
    $visibleSearchResultItems.slice(0, MAX_SEARCH_RESULTS_TO_SHOW).show();

    if (!$linkMore.length && moreResultsThanAllowed) {
        $listFilter.append(`
                <li class="link-more">
                    <a href="${getSearchUrl($('#search').val(), getSelectedProduct())}">See all results</a>
                </li>`
        );
    }
};

const getInitialProduct = () => {
    const queryParams = new URLSearchParams(location.search);
    const queryProduct = queryParams.get('product');

    if (queryProduct) {
        return queryProduct;
    }

    if (window.isGoUser) {
        return Product.GO;
    } else if (window.isLeapUser) {
        return Product.LEAP;
    } else if (window.isLeapEspUser) {
        return Product.XOLO_SPAIN;
    }

    return localStorage.getItem('selected-faq-product') || Product.ALL;
}

let selectedProduct = getInitialProduct();

const getSelectedProduct = () => {
    if (availableProducts.includes(selectedProduct)) {
        return selectedProduct;
    }

    return Product.ALL;
}

const handleSearchFilters = () => {
    $(document).on('click', '.filter', function() {
        const $this = $(this),
            $searchResultItems = $('.ui-menu-item'),
            $filterProduct = $this.data('product'),
            $filterItems = $('.filter');

        $filterItems.removeClass('active');
        $this.addClass('active');

        selectedProduct = $filterProduct;
        localStorage.setItem('selected-faq-product', $filterProduct);
        replaceLinkMoreProduct($filterProduct);

        if ($filterProduct === Product.GO || $filterProduct === Product.LEAP || $filterProduct === Product.XOLO_SPAIN) {
            $searchResultItems.hide();
            $('.product-' + $filterProduct).show();
        } else {
            $searchResultItems.show();
        }

        checkForTooManyResults();
    });
}

const getSearchUrl = (term, product) => {
    return `${Config.options.rootUrl}${Config.options.regionAndLanguage}faq/search?term=${encodeURIComponent(term)}&product=${product}`;
}

const getSearchArticleUrl = (term, product) => {
    return `${Config.options.rootUrl}${Config.options.regionAndLanguage}faq/search-article?term=${encodeURIComponent(term)}&product=${product}`;
}

const replaceLinkMoreProduct = (product) => {
    const linkMore = $('.link-more > a');
    const url = new URL(linkMore.attr('href'), location);

    url.searchParams.set('product', product);

    linkMore.attr('href', url.toString());
}

export default Faq;
